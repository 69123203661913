import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import Obfuscate from "react-obfuscate"
import styles from "../../assets/css/nav.module.scss"

const NavItem = props => {
  const variants = {
    initial: {
      y: "100%",
      transition: {
        y: { stiffness: 100, velocity: -10 },
      },
    },
    active: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 100, velocity: -10 },
      },
    },
    inactive: {
      y: "-100%",
      opacity: 0,
      transition: {
        y: { stiffness: 100 },
      },
    },
    menuHover: {
      opacity: 0.5,
    },
    menuRest: {
      opacity: 1,
    },
    itemHover: {
      opacity: 1,
    },
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <motion.li
        className={styles.menuItem}
        variants={variants}
        whileHover={"itemHover"}
      >
        {props.value !== "Contact" ? (
          <Link to={props.to}>{props.value}</Link>
        ) : (
          <Obfuscate email={props.to} linkText="contact">
            {props.value}
          </Obfuscate>
        )}
      </motion.li>
    </div>
  )
}
export default NavItem
