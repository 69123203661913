import React, { useState, useContext, useEffect } from "react"
import { motion } from "framer-motion"
import { Context } from "../../context/Context"
import AnimatedNumber from "animated-number-react"
import SEO from "../../components/seo"

import styles from "../../assets/css/landing.module.scss"
import { useStaticQuery, graphql } from "gatsby"

const transition = {
  duration: 0.4,
  type: "tween",
  ease: [0.14, 0.8, 0.4, 1],
}

const variants = {
  wrapper: {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  },
  title: {
    initial: {
      y: "100%",
    },
    enter: {
      y: 0,
      transition: transition,
    },
    leave: {
      y: "-100%",
      transition: transition,
    },
  },
}
const Landing = ({ initialMount, ...props }) => {
  const queryData = useStaticQuery(graphql`
    query {
      allStrapiProject(sort: { fields: strapiId, order: ASC }) {
        edges {
          node {
            title
            strapiId
            titleImg {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  src
                }
              }
            }
            mobileImg {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  let { dispatch } = useContext(Context)

  const [progress, setProgress] = useState(0)
  const [media, setMedia] = useState([])

  const loadImage = (image, resolve, reject) => {
    const img = new Image()
    img.src = image
    img.onload = () => {
      resolve(image)
    }
    img.onerror = () => reject()
  }

  const loadVideo = (video, resolve, reject) => {
    let newVid = document.createElement("video")
    const isIOS = /iPhone|iPod|iPad/.test(navigator.userAgent)
    const isSafari = window.safari !== undefined
    if (isIOS || isSafari) {
      newVid.autoplay = true
      newVid.muted = true
      newVid.playsInline = true
    }

    newVid.addEventListener(
      "loadeddata",
      () => {
        resolve(video)
      },
      false
    )
    newVid.oncanplaythrough = () => {
      newVid.readyState > 3 ? resolve(video) : reject(video)
    }
    newVid.onerror = err => {
      reject(err)
    }
    newVid.src = video
  }

  const loadMedia = async media => {
    let promiseCounter = 0
    const promises = await media.map(medium => {
      return new Promise((resolve, reject) => {
        if (medium.type === "img") {
          loadImage(medium.url, resolve, reject)
        } else if (medium.type === "vid") {
          loadVideo(medium.url, resolve, reject)
        }
      }).then(() => {
        setProgress(
          Math.trunc((promiseCounter++ / (promises.length - 1)) * 100)
        )
      })
    })
    await Promise.all(promises)
    props.setLoaded(true)
  }

  useEffect(() => {
    const images = []
    const projects = queryData.allStrapiProject.edges
    const projectTitles = []
    const isMobile = window.matchMedia("only screen and (max-width: 760px)")
      .matches
    projects.forEach(project => {
      projectTitles[project.node.strapiId] = project.node.title
      if (isMobile && project.node.mobileImg !== null) {
        let src = project.node.mobileImg.childImageSharp.fluid.src
        images.push({ src: src })
        setMedia(media.push({ type: "img", url: src }))
      } else {
        let src = project.node.titleImg.childImageSharp.fluid.src
        images.push({ src: src })
        setMedia(media.push({ type: "img", url: src }))
      }
      if (project.node.gallery !== undefined) {
        project.node.gallery.forEach(item => {
          let type
          if (item.url.split(".")[3] === "webm") {
            type = "vid"
          } else {
            type = "img"
          }
          setMedia(media.push({ type: type, url: item.url }))
        })
      }
    })
    setMedia(
      media.push({
        type: "img",
        url:
          "https://res.cloudinary.com/djb52dt4q/image/upload/v1606670833/me_y6tm9b.png",
      })
    )
    loadMedia(media)
    dispatch({
      type: "setImg",
      data: {
        thumbnails: images,
        aboutImage:
          "https://res.cloudinary.com/djb52dt4q/image/upload/v1606670833/me_y6tm9b.png",
      },
    })
    dispatch({ type: "setProjectTitles", data: projectTitles })
  }, [])

  const formatValue = value => `${Number(value).toFixed()}%`

  return (
    <>
      <SEO title="Loading..." />
      <div key="Landing" className={styles.wrapper}>
        <motion.span
          variants={variants}
          initial="initial"
          animate="enter"
          exit="leave"
          onAnimationComplete={() => props.setAnimDone(true)}
        >
          <div className={styles.textWrapper}>
            <motion.div variants={variants.title}>Valentin Horzinek</motion.div>
          </div>
          {
            <div className={styles.textWrapper}>
              <motion.div variants={variants.title}>
                <AnimatedNumber value={progress} formatValue={formatValue} />
              </motion.div>
            </div>
          }
        </motion.span>
      </div>
    </>
  )
}

export default Landing
