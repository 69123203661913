import React, { useState } from "react"
import styles from "../../assets/css/nav.module.scss"
import { motion } from "framer-motion"
import NavItem from "./NavItem"

const NavList = ({ variants, links, active, ...props }) => {
  const [menuHover, setMenuHover] = useState(false)

  return (
    <motion.div
      key="parent"
      className={styles.menu}
      initial={"initial"}
      animate={menuHover ? "menuHover" : "active"}
      exit={"inactive"}
    >
      <motion.ul
        className={styles.menuList}
        variants={variants.menuItems}
        onHoverStart={() => setMenuHover(true)}
        onHoverEnd={() => {
          active && setMenuHover(false)
        }}
      >
        {links.map((link, i) => {
          return (
            <NavItem
              key={i}
              index={i}
              value={link.value}
              to={link.to}
              setActive={props.setActive}
            />
          )
        })}
      </motion.ul>
      <motion.div
        className={styles.menuBackground}
        variants={variants.menuBgAnim}
      />
    </motion.div>
  )
}

export default NavList
