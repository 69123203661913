import React from "react"

let Context = React.createContext()

let initialState = {
  images: {},
  selectedProject: 1,
  projectTitles: [],
}

let reducer = (state, action) => {
  switch (action.type) {
    default:
      return 0
    case "setImg":
      return { ...state, images: action.data }
    case "setProject":
      return { ...state, selectedProject: action.data }
    case "setProjectTitles":
      return { ...state, projectTitles: action.data }
  }
}

function ContextProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState)
  let value = { state, dispatch }

  return <Context.Provider value={value}>{props.children}</Context.Provider>
}

let ContextConsumer = Context.Consumer

export { Context, ContextProvider, ContextConsumer }
