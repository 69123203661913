import React, { useEffect, useState } from "react"
import { ContextProvider } from "../context/Context"
import Nav from "../components/Nav/Nav"
import Landing from "../components/Landing/Landing"
import "../assets/css/main.scss"
import { AnimatePresence, motion } from "framer-motion"

const Layout = ({ children, location }) => {
  const [initialMount, setInitialMount] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [animDone, setAnimDone] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", () => {
      updateWindowSize()
    })
    updateWindowSize()
    setInitialMount(true)
  }, [])

  const updateWindowSize = () => {
    document.documentElement.style.setProperty(
      "height",
      `${window.innerHeight}px`
    )
  }

  const links = [
    { value: "About", to: "/about" },
    { value: "Work", to: "/" },
    { value: "Contact", to: "valentin@horzinek.at" },
  ]

  return (
    <ContextProvider>
      <AnimatePresence exitBeforeEnter>
        {loaded && animDone ? (
          <React.Fragment key="main">
            <Nav links={links} text={"MENU"} style={{ textAlign: "right" }} />
            <AnimatePresence exitBeforeEnter>
              <motion.main
                initial={"initial"}
                animate={"enter"}
                exit={"leave"}
                key={location.pathname}
                style={
                  location.pathname.includes("/projects/")
                    ? { height: "unset" }
                    : { height: "100%" }
                }
              >
                {children}
              </motion.main>
            </AnimatePresence>
          </React.Fragment>
        ) : (
          <Landing
            loaded={loaded}
            initialMount={initialMount}
            setLoaded={setLoaded}
            setAnimDone={setAnimDone}
          />
        )}
      </AnimatePresence>
    </ContextProvider>
  )
}

export default Layout
