import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import NavList from './NavList';
import styles from '../../assets/css/nav.module.scss';
import { globalHistory } from '@reach/router';

const Nav = ({ links, text, style, scrollIntoView }) => {
	const [ active, setActive ] = useState(false);
	const [ animating, setAnimating ] = useState(false);
	const variants = {
		menuBgAnim: {
			initial: {
				opacity: 0
			},
			active: {
				opacity: 1
			},
			inactive: {
				opacity: 0
			}
		},
		menuBtnAnim: {
			initial: {
				opacity: 0,
				transform: 'translateY(10px)'
			},
			active: {
				opacity: 0,
				transform: 'translateY(-10px)'
			},
			inactive: {
				opacity: 1,
				transform: 'translateY(0px)'
			}
		},
		closeBtnAnim: {
			initial: {
				opacity: 0,
				transform: 'translateY(-10px)'
			},
			active: {
				opacity: 1,
				transform: 'translateY(0px)'
			},
			inactive: {
				opacity: 0,
				transform: 'translateY(10px)'
			}
		},
		menuItems: {
			active: {
				transition: { staggerChildren: 0.05, delayChildren: 0.1 }
			}
		}
	};

	useEffect(() => {
		return globalHistory.listen(({ action }) => {
			if (action === 'PUSH') {
				setActive(false);
			}
		});
	}, []);

	return (
		<motion.nav
			initial={'initial'}
			animate={active ? 'active' : 'inactive'}
			onAnimationStart={() => setAnimating(true)}
			onAnimationComplete={() => setAnimating(false)}
			style={active ? { zIndex: 10000 } : {}}
		>
			<div className={styles.navButtonWrapper}>
				<motion.button
					className={styles.navButton}
					onClick={() => !animating && setActive(true)}
					variants={variants.menuBtnAnim}
					style={Object.assign({ ...style }, !active ? { cursor: 'pointer', zIndex: 2 } : {})}
				>
					{text}
				</motion.button>
				<motion.button
					className={styles.navButton}
					onClick={() => !animating && setActive(false)}
					variants={variants.closeBtnAnim}
					style={Object.assign({ ...style }, active ? { cursor: 'pointer' } : {})}
				>
					CLOSE
				</motion.button>
				<div className={styles.navButtonFake}>{text}</div>
			</div>
			<AnimatePresence>
				{active && <NavList variants={variants} links={links} setActive={setActive} />}
			</AnimatePresence>
		</motion.nav>
	);
};
export default Nav;
