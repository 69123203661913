/* import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"

import CustomLayout from "./src/context/wrapPageElement"
export const wrapPageElement = CustomLayout

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}
 */
// gatsby-browser.js
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  return false
}
